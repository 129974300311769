<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
      {{ $t('page.title.author_list') }}
      <router-link :to="{'name':'author-new'}">
        <b-button size="sm" variant="outline-primary">{{ $t('btn.add') }}</b-button>
      </router-link>
    </page-header>
    <page-card>
      <template v-slot:header>
        {{ $t('page.filters') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row">
            <div class="col-3">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  operator="like"
                  filter-name="id"
                  :label="$t('filters.label.id')"
                  :placeholder="$t('filters.placeholder.id')">
              </filter-input>
            </div>
            <div class="col-3">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  operator="like"
                  filter-name="firstName"
                  :label="$t('filters.label.first_name')"
                  :placeholder="$t('filters.placeholder.first_name')">
              </filter-input>
            </div>
            <div class="col-3">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  operator="like"
                  filter-name="lastName"
                  :label="$t('filters.label.last_name')"
                  :placeholder="$t('filters.placeholder.last_name')">
              </filter-input>
            </div>
            <div class="col-3">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="filterOptions.statuses"
                  :multiple="false"
                  options-label="text"
                  options-track-by="value"
                  operator="eq"
                  filter-name="status"
                  :label="$t('filters.label.status')"
                  :placeholder="$t('filters.placeholder.status')">
              </filter-select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <b-button @click="resetFilters()" variant="secondary">{{ $t('filters.btn.clear') }}</b-button>
        <b-button @click="applyFilters()" variant="primary">{{ $t('filters.btn.apply') }}</b-button>
      </template>
    </page-card>

    <page-card>
      <template v-slot:header>
        {{ $t('page.list') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-2">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">
                  <template #cell(actions)="data">
                    <div class="table-action--cell">
                      <b-button variant="primary" size="sm"
                                @click="showItemDetails(data.item)">
                        {{ $t('table.btn.show') }}
                      </b-button>
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>

import FilterInput from "../../../components/grid/filters/FilterInput";
import FilterSelect from "../../../components/grid/filters/FilterSelect";
import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";

export default {
  props: ['apiKey'],
  components: {
    PageCard,
    PageHeader,
    GridPagination,
    FilterInput,
    FilterSelect
  },
  data() {
    return {
      statuses: ['active', 'deleted'],
      orderColumn: null,
      orderDirection: false,
      fields: [
        {key: 'id', label: this.$t('table.header.id')},
        {key: 'firstName', label: this.$t('table.header.first_name')},
        {key: 'lastName', label: this.$t('table.header.last_name')},
        {key: 'status', label: this.$t('table.header.status')},
        {key: 'actions', label: this.$t('table.header.actions')},
      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Akcje masowe'}
        ],
      },
      filterQuery: [],
      filterOptions: {
        statuses: [
          {value: 'active', text: 'Active'},
          {value: 'inactive', text: 'Inactive'},
          {value: 'bonus', text: 'Bonus'},
          {value: 'deleted', text: 'Deleted'},
        ],

      },
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    this.loadItems();
    if (!this.filters) {
      this.initFilters();
    }
    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  computed: {
    ...mapState({
      perPage: (state) => state.authorsStore.perPage,
      currentPage: (state) => state.authorsStore.currentPage,
      items: (state) => state.authorsStore.items,
      isBusy: (state) => state.authorsStore.isBusy,
      totalCount: (state) => state.authorsStore.totalCount
    }),
    filters: function () {
      return this.$store.getters["root/filter"]('authors');
    },
  },
  methods: {
    storeFilter(data) {
      this.$store.commit('root/setFilter', {storeName: 'authors', ...data})
    },
    createSortQuery() {
      let sorting;
      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }
        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    loadItems() {
      this.$store.dispatch('authorsStore/loadItems', {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage
        },
        filters: this.filterQuery,
        sort: this.createSortQuery()
      });
    },
    changePage: function(currentPage) {
      this.$store.commit('authorsStore/setCurrentPage', currentPage);
    },
    applyFilters: function () {
      this.changePage(1);
      this.loadItems();
    },
    resetFilters() {
      this.filterQuery = [];
      this.$root.$emit('resetFilters');
      this.initFilters();
      this.applyFilters();
    },
    initFilters() {
      this.$store.commit('root/initFilters', {
        storeName: 'authors',
        filterNames: ['id', 'firstName', 'lastName', 'status']
      });
    },
    showItemDetails(item) {
      this.$router.push({name: 'author-details', params: {id: item.id}});
    },
    paginationReload(data) {
      this.changePage(data.currentPage);
      this.$store.commit('authorsStore/setPerPage', data.perPage);
      this.loadItems();
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function() {

    }
  }
}
</script>
