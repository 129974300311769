<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
      {{ $t('page.title.payment_list') }}
    </page-header>

    <page-card>
      <template v-slot:header>
        {{ $t('page.filters') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row">
            <div class="col-2">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="email"
                  :label="$t('filters.label.email')"
                  :placeholder="$t('filters.placeholder.email')">
              </filter-input>
            </div>
            <div class="col-3">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="externalId"
                  :label="$t('filters.label.externalId')"
                  :placeholder="$t('filters.placeholder.externalId')">
              </filter-input>
            </div>
            <div class="col-3">
              <filter-date-range-picker
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-name="createdAt"
                  :label="$t('filters.label.createdAt')"
              >
              </filter-date-range-picker>
            </div>
            <div class="col-2">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="filterOptions.source"
                  :multiple="false"
                  options-label="value"
                  options-track-by="key"
                  operator="eq"
                  filter-name="source"
                  :label="$t('filters.label.source')"
                  :placeholder="$t('filters.placeholder.source')">
              </filter-select>
            </div>
            <div class="col-2">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="filterOptions.invoiceRequired"
                  :multiple="false"
                  options-label="value"
                  options-track-by="key"
                  operator="eq"
                  filter-name="invoiceRequired"
                  :label="$t('filters.label.invoiceRequired')"
                  :placeholder="$t('filters.placeholder.invoiceRequired')">
              </filter-select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <b-button @click="resetFilters()" variant="secondary">{{ $t('filters.btn.clear') }}</b-button>
        <b-button @click="applyFilters()" variant="primary">{{ $t('filters.btn.apply') }}</b-button>
      </template>
    </page-card>



    <page-card>
      <template v-slot:header>
        {{ $t('page.list') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-2">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">


                  <template #cell(source)="data">
                    {{ $t('dictionary.paymentSource.'+data.value) }}
                  </template>

                  <template #cell(invoiceRequired)="data">
                    {{ data.value ? 'tak' : 'nie' }}
                  </template>

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>

import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";
import FilterInput from "@/components/grid/filters/FilterInput.vue";
import FilterSelect from "@/components/grid/filters/FilterSelect.vue";
import FilterDateRangePicker from "@/components/grid/filters/FilterDateRangePicker.vue";

export default {
  props: ['apiKey'],
  components: {
    PageCard,
    PageHeader,
    GridPagination,
    FilterInput,
    FilterSelect,
    FilterDateRangePicker
  },
  data() {
    return {
      orderColumn: 'createdAt',
      orderDirection: false,
      fields: [
        {key: 'email', label: this.$t('table.header.email'), sortable: true},
        {key: 'createdAt', label: this.$t('table.header.createdAt'), sortable: true},
        {key: 'source', label: this.$t('table.header.source'), sortable: true},
        {key: 'name', label: this.$t('table.header.name'), sortable: true},
        {key: 'length', label: this.$t('table.header.paymentLength'), sortable: true},
        {key: 'externalId', label: this.$t('table.header.externalId'), sortable: true},
        {key: 'status', label: this.$t('table.header.status'), sortable: true},
        {key: 'errorMessage', label: this.$t('table.header.errorMessage')},
        {key: 'invoiceRequired', label: this.$t('table.header.invoiceRequired'), sortable: true},

      ],
      selectOptions: {
        bulkActions: [
        ],
      },
      filterQuery: [],
      filterOptions: {
        source: [
          {key: 'subscription', value: 'Subskrypcja'},
          {key: 'score', value: 'Utwór'},
        ],
        invoiceRequired: [
          {key: 0, value: 'Nie'},
          {key: 1, value: 'Tak'},
        ]
      },
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    this.loadItems();
    if (!this.filters) {
      this.initFilters();
    }
    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  computed: {
    ...mapState({
      perPage: (state) => state.paymentStore.perPage,
      currentPage: (state) => state.paymentStore.currentPage,
      items: (state) => state.paymentStore.items,
      isBusy: (state) => state.paymentStore.isBusy,
      totalCount: (state) => state.paymentStore.totalCount,
    }),
    filters: function () {
      return this.$store.getters["root/filter"]('payment');
    },
  },
  methods: {
    storeFilter(data) {
      this.$store.commit('root/setFilter', {storeName: 'payment', ...data})
    },
    createSortQuery() {
      let sorting;

      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }

        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    setPerPage(page) {
      this.setPerPage = page;
    },
    loadItems() {
      this.$store.dispatch('paymentStore/loadItems', {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage
        },
        filters: this.filterQuery,
        sort: this.createSortQuery()
      });
    },
    changePage: function(currentPage) {
      this.$store.commit('paymentStore/setCurrentPage', currentPage);
    },
    applyFilters: function () {
      console.log(this.filters)
      this.changePage(1);
      this.loadItems();
    },
    resetFilters() {
      this.filterQuery = [];
      this.$root.$emit('resetFilters');
      this.initFilters();
      this.applyFilters();
    },
    initFilters() {
      this.$store.commit('root/initFilters', {
        storeName: 'payment',
        filterNames: []
      });
    },
    paginationReload(data) {
      this.changePage(data.currentPage);
      this.$store.commit('paymentStore/setPerPage', data.perPage);
      this.loadItems();
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function() {

    }
  }
}
</script>


