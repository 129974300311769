<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
      {{ $t('page.title.rates_list') }}
    </page-header>
    <page-card>
      <template v-slot:header>
        {{ $t('page.filters') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row">
            <div class="col-3">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="clientEmail"
                  :label="$t('filters.label.email')"
                  :placeholder="$t('filters.placeholder.email')">
              </filter-input>
            </div>
            <div class="col-3">
              <filter-date-range-picker
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-name="createdAt"
                  :label="$t('filters.label.createdAt')"
              >
              </filter-date-range-picker>
            </div>
              <div class="col-3">
                <filter-select
                    :filter-query="filterQuery"
                    @store="storeFilter"
                    :store-data="filters"
                    :options="filterOptions.sourceTypes"
                    :multiple="false"
                    options-label="value"
                    options-track-by="key"
                    operator="eq"
                    filter-name="sourceType"
                    :label="$t('filters.label.sourceType')"
                    :placeholder="$t('filters.placeholder.sourceType')">
                </filter-select>
            </div>
            <div class="col-3">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="authorsNames"
                  :label="$t('filters.label.author')"
                  :placeholder="$t('filters.placeholder.author')">
              </filter-input>
            </div>

          </div>
          <div class="row">
            <div class="col-3">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="name"
                  :label="$t('filters.label.sourceName')"
                  :placeholder="$t('filters.placeholder.sourceName')">
              </filter-input>
            </div>
            <div class="col-3">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="filterOptions.rates"
                  options-label="value"
                  options-track-by="key"
                  operator="eq"
                  filter-name="rate"
                  :label="$t('filters.label.rate')"
                  :placeholder="$t('filters.placeholder.rate')">
              </filter-select>
            </div>
            <div class="col-3">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="filterOptions.visible"
                  :multiple="false"
                  options-label="value"
                  options-track-by="key"
                  operator="eq"
                  filter-name="visible"
                  :label="$t('filters.label.visible')"
                  :placeholder="$t('filters.placeholder.visible')">
              </filter-select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <b-button @click="resetFilters()" variant="secondary">{{ $t('filters.btn.clear') }}</b-button>
        <b-button @click="applyFilters()" variant="primary">{{ $t('filters.btn.apply') }}</b-button>
      </template>
    </page-card>

    <page-card>
      <template v-slot:header>
        {{ $t('page.list') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-2">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">


                  <template #cell(sourceType)="data">
                    {{ $t('dictionary.sourceTypes.'+data.value) }}
                  </template>

                  <template #cell(visible)="data">
                    {{ data.value ? 'Tak' : 'Nie' }}
                  </template>

                  <template #cell(actions)="data">
                    <div class="table-action--cell">

                      <b-button v-if="data.item.visible" variant="danger" size="sm"
                                @click="makeInvisible(data.item.clientId, data.item.id)">
                        {{ $t('table.btn.make_invisible') }}
                      </b-button>

                      <b-button v-else variant="success" size="sm"
                                @click="makeVisible(data.item.clientId, data.item.id)">
                        {{ $t('table.btn.make_visible') }}
                      </b-button>
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>

import FilterInput from "../../../components/grid/filters/FilterInput";
import FilterSelect from "../../../components/grid/filters/FilterSelect";
import FilterDateRangePicker from "../../../components/grid/filters/FilterDateRangePicker.vue";
import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";
import axios from "axios";
import alertify from "alertifyjs";

export default {
  props: ['apiKey'],
  components: {
    PageCard,
    PageHeader,
    GridPagination,
    FilterInput,
    FilterSelect,
    FilterDateRangePicker

  },
  data() {
    return {
      orderColumn: 'createdAt',
      orderDirection: false,
      fields: [
        {key: 'clientEmail', label: this.$t('table.header.email'), sortable: true},
        {key: 'nick', label: this.$t('table.header.nick'), sortable: true},
        {key: 'createdAt', label: this.$t('table.header.rateCreatedAt'), sortable: true},
        {key: 'sourceType', label: this.$t('table.header.sourceType'), sortable: true},
        {key: 'authorsNames', label: this.$t('table.header.authorsNames'), sortable: true},
        {key: 'name', label: this.$t('table.header.rateName'), sortable: true},
        {key: 'rate', label: this.$t('table.header.rate'), sortable: true},
        {key: 'comment', label: this.$t('table.header.rateComment')},
        {key: 'visible', label: this.$t('table.header.visible'), sortable: true},
        {key: 'actions', label: this.$t('table.header.actions')},
      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Akcje masowe'}
        ],
      },
      filterQuery: [],
      filterOptions: {
        rates: [
          {key: 1, value: 1},
          {key: 2, value: 2},
          {key: 3, value: 3},
          {key: 4, value: 4},
          {key: 5, value: 5},
        ],
        visible: [
          {key: 1, value: 'Widoczne'},
          {key: 0, value: 'Niewidoczne'},
        ],
        sourceTypes: [
          {key: 'author', value: 'Autor'},
          {key: 'lesson', value: 'Lekcja'},
          {key: 'master-class', value: 'Klasa'},
        ]
      },
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    this.loadItems();
    if (!this.filters) {
      this.initFilters();
    }
    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  computed: {
    ...mapState({
      perPage: (state) => state.ratesStore.perPage,
      currentPage: (state) => state.ratesStore.currentPage,
      items: (state) => state.ratesStore.items,
      isBusy: (state) => state.ratesStore.isBusy,
      totalCount: (state) => state.ratesStore.totalCount,
    }),
    filters: function () {
      return this.$store.getters["root/filter"]('rates');
    },
  },
  methods: {
    storeFilter(data) {
      this.$store.commit('root/setFilter', {storeName: 'rates', ...data})
    },
    createSortQuery() {
      let sorting;

      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }

        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    setPerPage(page) {
      this.setPerPage = page;
    },
    loadItems() {
      this.$store.dispatch('ratesStore/loadItems', {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage
        },
        filters: this.filterQuery,
        sort: this.createSortQuery()
      });
    },
    changePage: function(currentPage) {
      this.$store.commit('ratesStore/setCurrentPage', currentPage);
    },
    applyFilters: function () {
      console.log(this.filters)
      this.changePage(1);
      this.loadItems();
    },
    resetFilters() {
      this.filterQuery = [];
      this.$root.$emit('resetFilters');
      this.initFilters();
      this.applyFilters();
    },
    initFilters() {
      this.$store.commit('root/initFilters', {
        storeName: 'rates',
        filterNames: ['clientEmail', 'nick', 'sourceType', 'authorsName', 'name', 'rate', 'visible']
      });
    },
    makeVisible(clientId, rateId) {
      const url = '/rate/' + clientId +'/' + rateId + '/make-visible' ;
      axios.post(url)
          .then(() => {
            alertify.success('Opinia została upubliczniona');
            setTimeout(() => this.loadItems(), 1000);
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    makeInvisible(clientId, rateId) {
      const url = '/rate/' + clientId +'/' + rateId + '/make-invisible' ;
      axios.post(url)
          .then(() => {
            alertify.success('Opinia została ukryta');
            setTimeout(() => this.loadItems(), 1000);
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    paginationReload(data) {
      this.changePage(data.currentPage);
      this.$store.commit('ratesStore/setPerPage', data.perPage);
      this.loadItems();
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function() {

    }
  }
}
</script>


