<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
      {{ $t('page.title.score_list') }}
      <router-link :to="{'name':'score-new'}">
        <b-button size="sm" variant="outline-primary">{{ $t('btn.add') }}</b-button>
      </router-link>
    </page-header>
    <page-card>
      <template v-slot:header>
        {{ $t('page.filters') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row">
            <div class="col-2">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="id"
                  :label="$t('filters.label.id')"
                  :placeholder="$t('filters.placeholder.id')">
              </filter-input>
            </div>
            <div class="col-2">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="title"
                  :label="$t('filters.label.title')"
                  :placeholder="$t('filters.placeholder.title')">
              </filter-input>
            </div>
            <div class="col-2">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="additionalTitle"
                  :label="$t('filters.label.additional_title')"
                  :placeholder="$t('filters.placeholder.additional_title')">
              </filter-input>
            </div>
            <div class="col-2">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="genres"
                  :multiple="false"
                  options-label="value"
                  options-track-by="key"
                  operator="contain"
                  filter-name="genres"
                  :label="$t('filters.label.genre')"
                  :placeholder="$t('filters.placeholder.genre')">
              </filter-select>
            </div>
            <div class="col-2">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="authors"
                  :multiple="false"
                  options-label="value"
                  options-track-by="key"
                  operator="contain"
                  filter-name="musicAuthors"
                  :label="$t('filters.label.musicAuthor')"
                  :placeholder="$t('filters.placeholder.musicAuthor')">
              </filter-select>
            </div>
            <div class="col-2">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="authors"
                  :multiple="false"
                  options-label="value"
                  options-track-by="key"
                  operator="contain"
                  filter-name="lyricsAuthors"
                  :label="$t('filters.label.lyricsAuthor')"
                  :placeholder="$t('filters.placeholder.lyricsAuthor')">
              </filter-select>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="authors"
                  :multiple="false"
                  options-label="value"
                  options-track-by="key"
                  operator="contain"
                  filter-name="arrangementAuthors"
                  :label="$t('filters.label.arrangementAuthor')"
                  :placeholder="$t('filters.placeholder.arrangementAuthor')">
              </filter-select>
            </div>
            <div class="col-2">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="scoreTypes"
                  :multiple="false"
                  options-label="value"
                  options-track-by="key"
                  operator="contain"
                  filter-name="scoreTypes"
                  :label="$t('filters.label.scoreType')"
                  :placeholder="$t('filters.placeholder.scoreType')">
              </filter-select>
            </div>
            <div class="col-5">
              <filter-select
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  :options="lineUp"
                  :multiple="false"
                  options-label="value"
                  options-track-by="key"
                  operator="contain"
                  filter-name="lineUp"
                  :label="$t('filters.label.lineUp')"
                  :placeholder="$t('filters.placeholder.lineUp')">
              </filter-select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <b-button @click="resetFilters()" variant="secondary">{{ $t('filters.btn.clear') }}</b-button>
        <b-button @click="applyFilters()" variant="primary">{{ $t('filters.btn.apply') }}</b-button>
      </template>
    </page-card>

    <page-card>
      <template v-slot:header>
        {{ $t('page.list') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-2">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">

                  <template #cell(genres)="data">
                   <span v-for="(genre, index) in data.value" :key="genre">
                      {{ $t('dictionary.genres.'+genre) }}<span v-if="index != data.value.length - 1">, </span>
                    </span>
                  </template>

                  <template #cell(scoreTypes)="data">
                    <span v-for="(scoreType, index) in data.value" :key="scoreType">
                      {{ $t('dictionary.score_types.'+scoreType) }}<span v-if="index != data.value.length - 1">, </span>
                    </span>
                  </template>

                  <template #cell(lineUp)="data">
                    <span v-for="(item, index) in data.value" :key="item">
                      {{ $t('dictionary.line_up.'+item) }}<span v-if="index != data.value.length - 1">, </span>
                    </span>
                  </template>

                  <template #cell(musicAuthors)="data">
                    <span v-for="(authorId, index) in data.value" :key="authorId">
                      {{ getAuthorById(authorId) }}<span v-if="index != data.value.length - 1">, </span>
                    </span>
                  </template>

                  <template #cell(lyricsAuthors)="data">
                    <span v-for="(authorId, index) in data.value" :key="authorId">
                      {{ getAuthorById(authorId) }}<span v-if="index != data.value.length - 1">, </span>
                    </span>
                  </template>

                  <template #cell(arrangementAuthors)="data">
                    <span v-for="(authorId, index) in data.value" :key="authorId">
                      {{ getAuthorById(authorId) }}<span v-if="index != data.value.length - 1">, </span>
                    </span>
                  </template>

                  <template #cell(actions)="data">
                    <div class="table-action--cell">
                      <b-button variant="primary" size="sm"
                                @click="showItemDetails(data.item)">
                        {{ $t('table.btn.show') }}
                      </b-button>
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>

import FilterInput from "../../../components/grid/filters/FilterInput";
import FilterSelect from "../../../components/grid/filters/FilterSelect";
import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";

export default {
  props: ['apiKey'],
  components: {
    PageCard,
    PageHeader,
    GridPagination,
    FilterInput,
    FilterSelect
  },
  data() {
    return {
      statuses: ['active', 'deleted'],
      orderColumn: null,
      orderDirection: false,
      fields: [
        {key: 'id', label: this.$t('table.header.id')},
        {key: 'title', label: this.$t('table.header.title')},
        {key: 'additionalTitle', label: this.$t('table.header.additional_title')},
        {key: 'genres', label: this.$t('table.header.genres')},
        {key: 'musicAuthors', label: this.$t('table.header.musicAuthor')},
        {key: 'lyricsAuthors', label: this.$t('table.header.lyricsAuthor')},
        {key: 'arrangementAuthors', label: this.$t('table.header.arrangementAuthor')},
        {key: 'scoreTypes', label: this.$t('table.header.scoreTypes')},
        {key: 'lineUp', label: this.$t('table.header.lineUp')},
        {key: 'actions', label: this.$t('table.header.actions')},
      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Akcje masowe'}
        ],
      },
      filterQuery: [],
      filterOptions: {
        genres : []
      },
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    this.getGenres();
    this.getAuthors();
    this.getScoreTypes();
    this.getLineUp();
    this.loadItems();
    if (!this.filters) {
      this.initFilters();
    }
    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  computed: {
    ...mapState({
      perPage: (state) => state.scoresStore.perPage,
      currentPage: (state) => state.scoresStore.currentPage,
      items: (state) => state.scoresStore.items,
      isBusy: (state) => state.scoresStore.isBusy,
      totalCount: (state) => state.scoresStore.totalCount,
      genres: (state) => state.scoresStore.genres,
      authors: (state) => state.scoresStore.authors,
      scoreTypes: (state) => state.scoresStore.scoreTypes,
      lineUp: (state) => state.scoresStore.lineUp,
    }),
    filters: function () {
      return this.$store.getters["root/filter"]('scores');
    },
  },
  methods: {
    storeFilter(data) {
      this.$store.commit('root/setFilter', {storeName: 'scores', ...data})
    },
    createSortQuery() {
      let sorting;
      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }
        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    setPerPage(page) {
      this.setPerPage = page;
    },
    loadItems() {
      this.$store.dispatch('scoresStore/loadItems', {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage
        },
        filters: this.filterQuery,
        sort: this.createSortQuery()
      });
    },
    getGenres() {
      this.$store.dispatch('scoresStore/getGenres');
    },
    getAuthors() {
      this.$store.dispatch('scoresStore/getAuthors');
    },
    getScoreTypes() {
      this.$store.dispatch('scoresStore/getScoreTypes');
    },
    getLineUp() {
      this.$store.dispatch('scoresStore/getLineUp');
    },
    getAuthorById(id) {
      let obj = this.authors.find(author => author.key === id);

      return obj ? obj.value : null;
    },
    changePage: function(currentPage) {
      this.$store.commit('scoresStore/setCurrentPage', currentPage);
    },
    applyFilters: function () {
      this.changePage(1);
      this.loadItems();
    },
    resetFilters() {
      this.filterQuery = [];
      this.$root.$emit('resetFilters');
      this.initFilters();
      this.applyFilters();
    },
    initFilters() {
      this.$store.commit('root/initFilters', {
        storeName: 'scores',
        filterNames: ['id', 'title', 'status', 'genres', 'musicAuthors', 'lyricsAuthors', 'arrangementAuthors', 'scoreTypes', 'lineUp']
      });
    },
    showItemDetails(item) {
      this.$router.push({name: 'score-details', params: {id: item.id}});
    },
    paginationReload(data) {
      this.changePage(data.currentPage);
      this.$store.commit('scoresStore/setPerPage', data.perPage);
      this.loadItems();
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function() {

    }
  }
}
</script>


