<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
      {{ $t('page.title.rental_list') }}
    </page-header>

    <page-card>
      <template v-slot:header>
        {{ $t('page.list') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-2">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">


                  <template #cell(status)="data">
                    {{ $t('dictionary.rentalScoreStatus.'+data.value) }}
                  </template>

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>

import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";

export default {
  props: ['apiKey'],
  components: {
    PageCard,
    PageHeader,
    GridPagination,

  },
  data() {
    return {
      orderColumn: 'activatedAt',
      orderDirection: false,
      fields: [
        {key: 'clientEmail', label: this.$t('table.header.email'), sortable: true},
        {key: 'scoreId', label: this.$t('table.header.scoreId')},
        {key: 'scoreName', label: this.$t('table.header.scoreName'), sortable: true},
        {key: 'activatedAt', label: this.$t('table.header.activatedAt'), sortable: true},
        {key: 'expireAt', label: this.$t('table.header.expireAt'), sortable: true},
        {key: 'status', label: this.$t('table.header.status')},
      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Akcje masowe'}
        ],
      },
      filterQuery: [],
      filterOptions: {
      },
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    this.loadItems();
    if (!this.filters) {
      this.initFilters();
    }
    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  computed: {
    ...mapState({
      perPage: (state) => state.rentalStore.perPage,
      currentPage: (state) => state.rentalStore.currentPage,
      items: (state) => state.rentalStore.items,
      isBusy: (state) => state.rentalStore.isBusy,
      totalCount: (state) => state.rentalStore.totalCount,
    }),
    filters: function () {
      return this.$store.getters["root/filter"]('rental');
    },
  },
  methods: {
    storeFilter(data) {
      this.$store.commit('root/setFilter', {storeName: 'rental', ...data})
    },
    createSortQuery() {
      let sorting;

      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }

        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    setPerPage(page) {
      this.setPerPage = page;
    },
    loadItems() {
      this.$store.dispatch('rentalStore/loadItems', {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage
        },
        filters: this.filterQuery,
        sort: this.createSortQuery()
      });
    },
    changePage: function(currentPage) {
      this.$store.commit('rentalStore/setCurrentPage', currentPage);
    },
    applyFilters: function () {
      console.log(this.filters)
      this.changePage(1);
      this.loadItems();
    },
    resetFilters() {
      this.filterQuery = [];
      this.$root.$emit('resetFilters');
      this.initFilters();
      this.applyFilters();
    },
    initFilters() {
      this.$store.commit('root/initFilters', {
        storeName: 'rates',
        filterNames: []
      });
    },
    paginationReload(data) {
      this.changePage(data.currentPage);
      this.$store.commit('ratesStore/setPerPage', data.perPage);
      this.loadItems();
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function() {

    }
  }
}
</script>


