<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
      <h3 class="card-title">{{ $t('page.title.client_favorite') }} dla <span
          v-if="client">{{ client.email }} ({{ client.id }})</span></h3>

      <div class="float-right">
        <router-link :to="{'name':'client-details',  params: { id: clientId } }">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>

    </page-header>

    <page-card>
      <template v-slot:header>
        {{ $t('page.list') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-2">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>

import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";

export default {
  props: ['apiKey'],
  components: {
    PageCard,
    PageHeader,
    GridPagination,
  },
  data() {
    return {
      orderColumn: null,
      clientId: null,
      orderDirection: false,
      fields: [
        {key: 'name', label: this.$t('table.header.name')},
        {key: 'source', label: this.$t('table.header.clientFavorite.source')},
        {key: 'createdAt', label: this.$t('table.header.clientFavorite.createdAt')},
      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Bulk actions'}
        ],
      },
      filterQuery: [],
      filterOptions: {
      },
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    this.getClient();
    this.loadItems();
    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  created() {
    this.clientId = this.$route.params.id;
  },
  computed: {
    ...mapState({
      perPage: (state) => state.clientsStore.perPage,
      currentPage: (state) => state.clientsStore.currentPage,
      items: (state) => state.clientsStore.items,
      isBusy: (state) => state.clientsStore.isBusy,
      totalCount: (state) => state.clientsStore.totalCount,
    }),
    client: function () {
      return this.$store.getters["clientsStore/client"];
    },
  },
  methods: {
    getClient() {
      this.$store.dispatch('clientsStore/getClient', this.clientId);
    },
    createSortQuery() {
      let sorting;
      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }
        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    loadItems() {
      this.$store.dispatch('clientsStore/getFavorite', {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage
        },
        filters: this.filterQuery,
        clientId: this.clientId,
        sort: this.createSortQuery()
      });
    },
    changePage: function(currentPage) {
      this.$store.commit('clientsStore/setCurrentPage', currentPage);
    },
    paginationReload(data) {
      this.$store.commit('clientsStore/setPerPage', data.perPage);
      this.changePage(data.currentPage);
      this.loadItems();
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function() {

    }
  }
}
</script>
