<template>
  <div>
    <b-form-group :label="label"
                  class="extra-margin big-label  form-group--small">
    </b-form-group>
    <date-range-picker v-model="dateRange"
                       ref="picker"
                       :opens="openDirection"
                       :locale-data="locale"
                       :auto-apply="auto"
                       :ranges="generateRanges()"
                       :linkedCalendars="linked"
                       :single-date-picker="singleDatePicker"
                       :timePicker="timePicker"
                       :time-picker-increment="1"
                       :timePicker24Hour="true"
                       @update="applyFilter">
      <template v-slot:input="picker" style="min-width: 350px;">
        <div v-if="!timePicker">{{ picker.startDate | date }} - {{ picker.endDate | date }}</div>
        <div v-if="timePicker">{{ picker.startDate | dateTime }} - {{ picker.endDate | dateTime }}</div>
      </template>
    </date-range-picker>
    <b-icon-x-circle v-if="dateRange.endDate != null" variant="danger"
                     @click="arguments[0].stopPropagation(); clearDate()"
                     :style="{top: label ? '35px' : '0'}">
    </b-icon-x-circle>
    <b-icon-calendar3 v-if="dateRange.endDate === null"
                      @click="arguments[0].stopPropagation(); $refs.picker.togglePicker(true/false)"
                      :style="{top: label ? '35px' : '0'}">
    </b-icon-calendar3>
  </div>
</template>

<script>

import moment from "moment";
import DateRangePicker from "../../DateRangePicker/components/DateRangePicker";

export default {
  name: "CustomDateRangePicker",
  props: ['filterName', 'filterQuery', 'label', 'openDirection', 'locale', 'auto', 'linked', 'timePicker', 'storeData', 'singleDatePicker'],
  components: {DateRangePicker},
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null
      }
    };
  },
  filters: {
    date: function (value) {
      if (value) {
        return new moment(value).format('YYYY-MM-DD');
      }
    },
    dateTime: function (value) {
      if (value) {
        return new moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    }
  },
  mounted() {
    this.$root.$on('resetFilters', () => {
      this.clearDate();
    });

    this.checkStoreData();
  },
  methods: {
    checkStoreData() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.storeData && this.storeData.hasOwnProperty(this.filterName)) {
        if (this.storeData[this.filterName] !== null) {
          this.dateRange = {
            startDate: this.storeData[this.filterName].startDate,
            endDate: this.storeData[this.filterName].endDate
          };
          this.applyFilter();
        }
      }
    },
    clearDate() {
      delete this.filterQuery[this.filterName];
      this.dateRange = {
        startDate: null,
        endDate: null
      };
      this.$emit('store', {filterQuery: this.filterQuery[this.filterName], filterName: this.filterName, value: null});
    },
    generateRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999);

      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0);

      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(23, 59, 59, 999);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);
      return {
        'Today': [today, todayEnd],
        'Yesterday': [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
        ],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
        ],
      }
    },
    applyFilter() {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        if (this.timePicker === false) {
          this.dateRange.startDate.setHours(0,0,0);
          this.dateRange.endDate.setHours(23,59,59);
        }
        this.filterQuery[this.filterName] = '&filters[' + this.filterName + '][0][value]=' + encodeURIComponent(this.dateFormat(this.dateRange.startDate)) + '&filters[' + this.filterName + '][0][operator]=gte'
        this.filterQuery[this.filterName] += '&filters[' + this.filterName + '][1][value]=' + encodeURIComponent(this.dateFormat(this.dateRange.endDate)) + '&filters[' + this.filterName + '][1][operator]=lte'
        this.$emit('store', {filterQuery: this.filterQuery[this.filterName], filterName: this.filterName, value: {startDate: this.dateFormat(this.dateRange.startDate), endDate: this.dateFormat(this.dateRange.endDate)}});
      } else {
        delete this.filterQuery[this.filterName];
        this.$emit('store', {filterQuery: this.filterQuery[this.filterName], filterName: this.filterName, value: null});
      }
    },
    dateFormat: function (value) {
      if (value) {
        return moment(value).format();
      }
    },
  }
}
</script>
